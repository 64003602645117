<template>
  <CRow>
    <CCol col="12" lg="12">
      <div class="detail-div">
        <CRow>
          <div>
            <CModal
                :title="
                submitType === 'Update'
                  ? $lang.post.crud.edit
                  : $lang.common.general.reason
              "
                size="lg"
                :show.sync="largeModal"
                :no-close-on-backdrop="true"
                color="primary"
            >
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <c-textarea
                        v-model="resource.reason"
                        :rows="5"
                        :cols="10"
                        placeholder="Add Reason"
                    ></c-textarea>
                  </CCol>
                  <CCol md="6">
                    <div class="form-group">
                      <CButton
                          color="primary"
                          v-c-tooltip="$lang.buttons.general.crud.submit"
                          :disabled="submitted"
                          v-on:click="blockPost(statusId)"
                      >
                        Submit
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
              <template #footer style="display: none">
                <CButton
                    @click="darkModal = false"
                    color="danger"
                    style="display: none"
                >Discard
                </CButton
                >
                <CButton
                    @click="darkModal = false"
                    color="success"
                    style="display: none"
                >Accept
                </CButton
                >
              </template>
            </CModal>
          </div>
        </CRow>
        <CRow>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.common.form.user }}</p>
            <p class="custome-detail">
              <ProfilerDetail :username="resource.userWithCompany" :profilerId="resource.userId"></ProfilerDetail>
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.branchInfo">
            <p class="custome-labal">{{ $lang.post.form.branch }}</p>
            <BranchComponent :data="resource.branchInfo"></BranchComponent>
          </CCol>


          <CCol sm="6" md="4" lg="4" v-show="resourceDetail.title">
            <p class="custome-labal">{{ $lang.resource.form.title }}</p>
            <p class="custome-detail">{{ resourceDetail.title }}</p>
          </CCol>


          <CCol sm="6" md="4" lg="4" v-show="resource.firstname">
            <p class="custome-labal">{{ $lang.resource.form.firstname }}</p>
            <p class="custome-detail">{{ resource.firstname }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.lastname">
            <p class="custome-labal">{{ $lang.resource.form.lastname }}</p>
            <p class="custome-detail">{{ resource.lastname }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.nickname">
            <p class="custome-labal">{{ $lang.resource.form.nickname }}</p>
            <p class="custome-detail">{{ resource.nickname }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.resourceType.label">
            <p class="custome-labal">{{ $lang.resource.form.resourceType }}</p>
            <p class="custome-detail">{{ resource.resourceType.label }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.resource.form.speackWithClient }}
            </p>
            <p class="custome-detail" v-if="resource.speackWithClient === 1">
              Yes
            </p>
            <p class="custome-detail" v-if="resource.speackWithClient === 0">
              No
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.isRemote">
            <p class="custome-labal">{{ $lang.resource.form.isRemote }}</p>
            <p class="custome-detail">{{ resource.isRemote }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="resourceDetail.availability">
            <p class="custome-labal">{{ $lang.resource.form.availability }}</p>
            <p class="custome-detail">{{ resourceDetail.availability }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resourceDetail.experience">
            <p class="custome-labal">{{ $lang.resource.form.experience }}</p>
            <p class="custome-detail">{{ resourceDetail.experience }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="resource.currency">
            <p class="custome-labal">{{ $lang.resource.form.currency }}</p>
            <p class="custome-detail">{{ resource.currency }}</p>
          </CCol>


          <CCol sm="6" md="4" lg="4" v-show="resource.skills">
            <p class="custome-labal">{{ $lang.resource.form.skills }}</p>
            <p class="custome-detail">
              {{ resource.skills }}
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.skillOthers">
            <p class="custome-labal">{{ $lang.resource.form.skillOthers }}</p>
            <p class="custome-detail">
              {{ resource.skillOthers }}
            </p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="resource.fromPricePerHours">
            <p class="custome-labal">
              {{ $lang.resource.form.fromPricePerHours }}
            </p>
            <p class="custome-detail">{{ resource.symbol }}&nbsp;{{ resource.fromPricePerHours }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.toPricePerHours">
            <p class="custome-labal">
              {{ $lang.resource.form.toPricePerHours }}
            </p>
            <p class="custome-detail">{{ resource.toPricePerHours }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.fromPricePerMonth">
            <p class="custome-labal">
              {{ $lang.resource.form.fromPricePerMonth }}
            </p>
            <p class="custome-detail">{{ resource.fromPricePerMonth }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.toPricePerMonth">
            <p class="custome-labal">
              {{ $lang.resource.form.toPricePerMonth }}
            </p>
            <p class="custome-detail">{{ resource.toPricePerMonth }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="resource.languages">
            <p class="custome-labal">{{ $lang.resource.form.languages }}</p>
            <p class="custome-detail">
              {{ resource.languages }}
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.country">
            <p class="custome-labal">{{ $lang.resource.form.country }}</p>
            <p class="custome-detail">{{ resource.country }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="resource.isStatus">
            <p class="custome-labal">{{ $lang.resource.form.isActive }}</p>
            <p class="custome-detail">
              <CBadge :color="statusBudget(resource.isStatus)">{{ resource.isStatus }}</CBadge>
            </p>
          </CCol>
        </CRow>
        <entity-time-stamp :createdAt="resource.createdAt" :updatedAt="resource.updatedAt"></entity-time-stamp>
        <CRow v-show="resource.blockedReason">
          <CCol sm="12" align="left">
            <strong>Blocked Reason: </strong> {{ resource.blockedReason }}
          </CCol>
        </CRow>
        <CRow v-if="!resource.blockedReason">
          <CCol sm="12" align="left">
            <CButton
                :color="statusColor"
                v-c-tooltip="statusLabel"
                v-on:click="beforeBlocked()"
            >
              {{ statusLabel }}
            </CButton>
          </CCol>
        </CRow>


      </div>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import BranchComponent from "/src/views/branch/Component";
import {
  resource,
  currencytype,
  users,
  skills,
  language,
  country,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import {availability, experience, resourcetype} from "/src/store/url";
import ProfilerDetail from "/src/views/ProfilerDetail.vue";
import EntityTimeStamp from "/src/components/EntityTimeStamp";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "ResourceCreate",
  mixins: [Mixin],
  components: {
    ProfilerDetail,
    EntityTimeStamp,
    BranchComponent
  },
  data() {
    return {
      resourceDetail: [],
      largeModal: false,
      submitType: "",
      statusId: "",
      statusLabel: "Block",
      statusColor: "danger",
      submitted: false,
      err_msg: "",
      dismissCountDown: 0,
      remoteWorkOptions: [],
      skillsOptions: [],
      industryOptions: [],
      subIndustryOptions: [],
      currencyOptions: [],
      durationOptions: [],
      userOptions: [],
      getSkills: [],
      statusOption: [],
      getLanguage: [],
      resourceTypeOptions: [],
      experienceOptions: [],
      availabilityOptions: [],
      languageOptions: [],
      countryOptions: [],
      self: this,
      module: resource,
      moduleCurrency: currencytype,
      moduleSkills: skills,
      moduleresourceType: resourcetype,
      moduleexperience: experience,
      moduleavailability: availability,
      modulelanguage: language,
      modulecountry: country,
      editId: this.$route.params.id,
      moduleUser: users,
      resource: {
        firstname: "",
        lastname: "",
        nickname: "",
        resourceType: "",
        speackWithClient: "",
        experience: "",
        avaibility: "",
        currency: "",
        fromPricePerHours: "",
        toPricePerHours: "",
        fromPricePerMonth: "",
        toPricePerMonth: "",
        skills: "",
        skillOthers: "",
        languages: "",
        country: "",
        isActive: "",
        isActives: "",
        isRemote: "",
        user: "",
        isStatus: "",
        userId: "",
        reason: "",
        symbol: "",
        userWithCompany: "",
        createdAt: "",
        updatedAt: "",
        branchInfo: "",
        blockedReason: '',
      },
    };
  },

  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.remoteWorkOptions.push(
        {value: "Yes", label: "Yes"},
        {value: "No", label: "No"}
    );
    self.dismissCountDown = 0;
    self.statusOption.push(
        {value: "1", label: "Active"},
        {value: "0", label: "InActive"},
        {value: "2", label: "Block"},
        {value: "3", label: "Closed"}
    );

    axios.get(this.viewUrlApi(this.module, self.editId)).then((response) => {
      if (response.data) {
        const data = response.data.data;
        self.resourceDetail = data;
        self.resource.firstname = data.firstName ?? '';
        self.resource.userWithCompany = data.userWithCompany ?? '';
        self.resource.lastname = data.lastName ?? '';
        self.resource.nickname = data.nickname ?? '';
        self.resource.nickname = data.nickname ?? '';
        self.resource.fromPricePerHours = data.fromPricePerHours ?? '';
        self.resource.toPricePerHours = data.toPricePerHours ?? '';
        self.resource.fromPricePerMonth = data.fromPricePerMonth ?? '';
        self.resource.toPricePerMonth = data.toPricePerMonth ?? '';
        self.resource.symbol = data.symbol ?? '';
        self.resource.createdAt = data.createdAt;
        self.resource.updatedAt = data.updatedAt;
        self.resource.avaibility = data.availability ? data.availability.value : '';
        self.resource.experience = data.experience ? data.experience.value : '';
        self.resource.currency = data.currency ? data.currency.label : '';
        self.resource.country = data.countryFull ? data.countryFull.label : '';
        self.resource.branchInfo = data.branchInfo ?? '';
        self.resource.blockedReason = data.blockedReason;

        self.resource.user = {value: data.userId, label: data.userName};
        self.resource.resourceType = {
          value: data.resourceId,
          label: data.resource,
        };
        self.resource.speackWithClient = data.speakWithClient ?? '-';
        self.resource.isActive = data.isActiveStatus;
        self.resource.isActives = data.isActive;
        self.resource.isRemote = data.remoteWork ?? 'No';
        self.resource.userId = data.userId;

        self.resource.isStatus = data.isActiveStatus;

        if (self.resource.isStatus === "Active") {
          self.statusLabel = 'Block';
          self.statusId = "2";
          self.statusColor = "danger";
        } else {
          self.statusId = "1";
          self.statusLabel = 'Active';
          self.statusColor = "success";
        }

        let j = [];
        data.language.map(function (value, key) {
          j.push(value.title);
        });
        self.resource.languages = j.join(", ");

        let k = [];
        data.skills.map(function (value, key) {
          k.push(value.title);
        });
        self.resource.skills = k.join(", ");

        let l = [];
        data.otherSkills.map(function (value, key) {
          l.push(value.title);
        });
        self.resource.skillOthers = l.join(", ");
      }
    });
  },
  methods: {

    getStatusLabel(getStatus) {
      let valObj = "";
      valObj = this.statusOption.filter(function (elem) {
        if (elem.value == getStatus) return elem.value;
      });
      if (valObj.length > 0) return valObj[0].label;
    },

    beforeBlocked() {
      let self = this;
      self.resource.reason = "";
      if (self.resource.isActives === 1) {
        this.largeModal = true;
      } else {
        this.blockPost(self.statusId);
      }
    },
    blockPost(statusId) {
      let self = this;
      self.submitted = true; //Enable Button
      if (self.resource.isActives === 1) {
        if (self.resource.reason === "") {
          self.submitted = false; //Enable Button
          return false;
        }
        this.$swal({
          title: this.$lang.common.status.want_to_block,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#e55353",
          confirmButtonText: this.$lang.common.status.yes_block,
          cancelButtonText: this.$lang.common.status.cancel,
        }).then((result) => {
          if (result.value) {
            const id = this.$route.params.id;
            setTimeout(() => {
              let self = this;
              const postData = {
                status: self.statusId,
                reason: self.resource.reason,
              };
              let status, button;
              if (statusId === "1") {
                self.statusId = "2";
                self.statusColor = "danger";
                status = this.getStatusLabel(1);
                button = "Block";
              } else {
                self.statusId = "1";
                self.statusColor = "success";
                status = "Blocked";
                button = this.getStatusLabel(1);
              }
              axios
                  .post(this.statusUrlWeb(this.module, id), postData)
                  .then(function (response) {
                    if (response.data.code === 200) {
                      self.resource.isStatus = status;
                      self.statusLabel = button;
                      self.largeModal = false;
                      self.submitted = false; //Enable Button
                      self.$router.go();
                    } else {
                      self.err_msg = response.data.message;
                      self.dismissCountDown = 10;
                    }
                  })
                  .catch(function (error) {
                    self.submitted = false; //Enable Button
                  });
            }, 500);
          }
        });
      } else {
        const id = this.$route.params.id;
        let self = this;
        const postData = {
          status: self.statusId,
          reason: "",
        };
        let status, button;
        if (statusId === "1") {
          self.statusId = "2";
          self.statusColor = "danger";
          status = this.getStatusLabel(1);
          button = "Block";
        } else {
          self.statusId = "1";
          self.statusColor = "success";
          status = "Blocked";
          button = this.getStatusLabel(1);
        }
        axios
            .post(this.statusUrlWeb(this.module, id), postData)
            .then(function (response) {
              if (response.data.code === 200) {
                self.resource.isStatus = status;
                self.statusLabel = button;
                self.largeModal = false;
                self.submitted = false; //Enable Button
                self.$router.go();
              } else {
                self.err_msg = response.data.message;
                self.dismissCountDown = 10;
              }
            })
            .catch(function (error) {
              self.submitted = false; //Enable Button
            });
      }
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
